import axios from 'axios';
import produce from 'immer';
import { cloneDeep, debounce, isArray, isDate, isEmpty, isObject, isString } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../../config/Config';
import { DataContext } from '../../context/DataContext';
import { calculateNewTimestampEndDay, convertTimestamp } from '../../helper/date/convertTimestamp';
import { handleMessage, parseQueryString } from '../../helper/utils';
import { createRangeDate, newCreateRangeDate, newCreateSingleDate } from '../../hooks/useDate';
import { buildQueryString } from '../Orders/Orders';
// import moment from 'moment';

export default function useLogicDriverNew({
  isLoading,
  setIsLoading,
  currentPage,
  setCurrentPage,
  setData,
  setTotal,
  limit,
  setLimit,
  data,
}) {
  const [selectedItem, setSelectedItem] = useState([]);
  const filtersRef = useRef({});
  const [firstLoadPage, setFirstLoadPage] = useState(false);
  const { currentTimezone } = useContext(DataContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [sendNotificationListDriver, setSendNotificationListDriver] = useState(false);

  const [selectedId, setSelectedId] = useState([]);
  // const [dataNew, setDataNew] = useState([]);
  // const [transaction, setTransaction] = useState([]);

  const { search } = useLocation();
  const queryString = parseQueryString(search);

  useEffect(() => {
    if (!firstLoadPage) {
      if (!isEmpty(queryString?.filters)) {
        Object.entries(queryString?.filters)?.map(([key, value]) => {
          if (['date', 'cities', 'company', 'status', 'transactionDate'].includes(key)) {
            //  date value
            if (key === 'date') {
              return (filtersRef.current[key] = newCreateRangeDate(value));
            }
            if (key === 'transactionDate') {
              return (filtersRef.current[key] = newCreateSingleDate(value));
            }
            //  select value
            filtersRef.current[key] = value;
          } else {
            // input value
            filtersRef.current[key].value = value;
          }
        });
      }
      setFirstLoadPage(true);
    } else {
      getDataTable(buildFilterOrderWithRef());
    }
  }, [firstLoadPage]);

  useEffect(() => {
    if (isEmpty(selectedItem)) {
      setSendNotificationListDriver(false);
    }
  }, [selectedItem]);

  const buildFilterOrderWithRef = (newCurrentPage) => {
    const newFilter = {};
    Object.entries(filtersRef.current).map(([key, valueRef]) => {
      if (isDate(valueRef) || isDate(valueRef)) {
        return (newFilter[key] = valueRef);
      }

      if (isString(valueRef) || isArray(valueRef)) {
        return (newFilter[key] = valueRef);
      }

      if (
        (isString(valueRef?.value) && valueRef?.value.length > 0) ||
        (isArray(valueRef?.value) && valueRef.value.length > 0)
      ) {
        newFilter[key] = valueRef.value;
      }
    });
    const fullParamFilter = {
      filters: {
        ...newFilter,
      },
      limit: limit,
      page: newCurrentPage ? newCurrentPage : currentPage,
    };
    return fullParamFilter;
  };

  const handlePageChange = (nextPage) => {
    if (isLoading) return;
    if (Number(nextPage) === Number(currentPage)) return false;
    setCurrentPage(nextPage);
    const newFilters = {
      ...buildFilterOrderWithRef(),
      ...{ page: nextPage },
    };

    getDataTable(newFilters);
  };

  const handleLimitChange = (newLimit) => {
    if (isLoading) return;
    setLimit(newLimit);

    const newFilters = {
      ...buildFilterOrderWithRef(),
      ...{ limit: newLimit },
      ...{ page: 1 },
    };

    getDataTable(newFilters);
  };

  const buildNewQueryStringWithFilter = (filters) => {
    const cloneDeepFilters = cloneDeep(filters);
    console.log('filters', filters);

    let driver_company = [];
    if (
      isArray(cloneDeepFilters?.filters?.company) &&
      cloneDeepFilters?.filters?.company.length > 0
    ) {
      cloneDeepFilters?.filters?.company.map((item) => driver_company.push(item?.id));

      cloneDeepFilters.filters.company = driver_company;
    }
    if (isObject(cloneDeepFilters?.filters?.isDiscount)) {
      cloneDeepFilters.filters.isDiscount = filters.filters.isDiscount.value;
    }
    if (cloneDeepFilters?.filters?.date) {
      cloneDeepFilters.filters.date = createRangeDate(filters?.filters?.date, currentTimezone);
    }
    if (cloneDeepFilters?.filters?.transactionDate) {
      cloneDeepFilters.filters.transactionDate = calculateNewTimestampEndDay(
        new Date(filters.filters.transactionDate).getTime()
      );
    }
    return cloneDeepFilters;
  };

  // async function getDataTransactions(driver_ids, transaction_date) {
  //   try {
  //     const response = await axios.post(
  //       config.API_URL.DRIVERS.TRANSACTIONS,
  //       { driver_ids: driver_ids, transaction_date: transaction_date },
  //       {
  //         headers: config.headers,
  //       }
  //     );
  //     const { data = {} } = response?.data;

  //     setTransaction(data || []);
  //   } catch (e) {
  //     console.log('Axios error: ', e);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  async function getDataTable(filters = {}) {
    if (isLoading === true) return;
    setIsLoading(true);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filters })}`;
    window.history.replaceState({ path: newurl }, '', newurl);

    try {
      const response = await axios.get(config.API_URL.DRIVERS.GET_ALL_DRIVERS_v2, {
        params: { ...buildNewQueryStringWithFilter(filters) },
        headers: config.headers,
      });
      const { data = {} } = response?.data;
      // const ids = data.results.map((driver) => driver._id);

      // const valueTransactionDate = filtersRef?.current?.transactionDate
      //   ? moment(filtersRef?.current?.transactionDate).format('ll')
      //   : '';

      // getDataTransactions(ids, valueTransactionDate);

      setData(data.results || []);
      setTotal(data.total);
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  }

  // const mergedArray = (array1, array2) =>
  //   array1.map((elementArr1) => {
  //     const element2 = array2.find((itemArr2) => elementArr1._id === itemArr2._id);

  //     if (!element2) return elementArr1;

  //     return { ...elementArr1, ...element2 };
  //   });

  // useEffect(() => {
  //   const newData = mergedArray(data, transaction);
  //   setDataNew(newData);
  // }, [data, transaction]);

  const refresh = () => {
    const newFilters = {
      ...buildFilterOrderWithRef(),
    };
    getDataTable(newFilters);
  };

  const handleGetDataTable = useCallback(
    debounce((filters) => getDataTable(filters), 500),
    [currentTimezone, isLoading]
  );
  const unAssignOrder = (id_number) => {
    const index = data.findIndex((i) => i._id === id_number);
    const toggle = produce(data, (draft) => {
      draft[index].driver_id = null;
      delete draft[index].driver;
    });
    setData(toggle);
  };
  const toggleModal = (visible) => {
    setModalVisible(visible);
  };
  const handleToggleModal = () => {
    toggleModal(true);
  };

  const getFilterObject = () => {
    return buildNewQueryStringWithFilter(buildFilterOrderWithRef());
  };

  const toggleBtnSendNotiDriver = () => {
    setModalVisible(true);
    setSendNotificationListDriver(true);
  };

  const onRowExpandToggled = (toggleState, row) => [
    toggleState
      ? setSelectedId((p) => [...p, row._id])
      : setSelectedId(selectedId.filter((i) => i !== row._id)),
  ];
  const toggleActivate = (id_number) => {
    const index = data.findIndex((i) => i.id_number === id_number);
    const toggle = produce(data, (draft) => {
      draft[index].isActive = !draft[index].isActive;
    });
    setData(toggle);
  };

  const toggleReady = (id_number) => {
    const index = data.findIndex((i) => i.id_number === id_number);
    const ready = produce(data, (draft) => {
      draft[index].isReady = !draft[index].isReady;
    });
    setData(ready);
  };

  async function enableByListDriver(driver_ids) {
    setIsLoading(true);

    try {
      const { status } = await axios.post(
        config.API_URL.DRIVERS.ODM_ENABLE_BY_LIST,
        { driver_ids: driver_ids },
        {
          headers: config.headers,
        }
      );
      if (status === 200) return handleMessage('success', 'Driver has been updated successfully');
    } catch (e) {
      console.log('Axios error: ', e);
      handleMessage('fail', 'Driver has been updated fail');
    } finally {
      setIsLoading(false);
    }
  }

  async function odmDisableAll(driver_ids) {
    setIsLoading(true);

    try {
      const { status } = await axios.post(
        config.API_URL.DRIVERS.ODM_DISABLE_ALL,
        { ignore_driver_ids: driver_ids },
        {
          headers: config.headers,
        }
      );
      if (status === 200) return handleMessage('success', 'Disable add odm successfully');
    } catch (e) {
      console.log('Axios error: ', e);
      handleMessage('fail', 'Disable add odm fail');
    } finally {
      setIsLoading(false);
    }
  }
  return {
    selectedItem,
    setSelectedItem,
    handleGetDataTable,
    handlePageChange,
    filtersRef,
    buildFilterOrderWithRef,
    handleLimitChange,
    refresh,
    buildNewQueryStringWithFilter,
    unAssignOrder,
    modalVisible,
    handleToggleModal,
    toggleModal,
    sendNotificationListDriver,
    getFilterObject,
    toggleBtnSendNotiDriver,
    onRowExpandToggled,
    selectedId,
    toggleActivate,
    toggleReady,
    enableByListDriver,
    odmDisableAll,
  };
}
