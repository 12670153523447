import { Dialog, Transition } from '@headlessui/react';
import Loading from '../../../../commonComponents/LoadingView/Loading';
import { FORMAT_DATE, parseDateWithGmt } from '../../../../helper/date/parseDateGmt';
import { useDate } from '../../../../hooks/useDate';

export default function HistoryModal({ isOpen, setIsOpen, dataHistory, loading }) {
  const { currentTimezone } = useDate();

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderDataHistory = () => {
    if (loading) {
      return <Loading />;
    }

    return dataHistory?.data?.map((data) => (
      <div key={data?._id} className="rounded-lg border p-10">
        <div className="flex justify-between">
          <div>
            <p>
              {parseDateWithGmt({
                date: data.created_at,
                timezone: currentTimezone?.timezone,
              })}
            </p>
            <p>
              {parseDateWithGmt({
                date: data.created_at,
                timezone: currentTimezone?.timezone,
                format: FORMAT_DATE.hms,
              })}
            </p>
          </div>
          <div>
            <p>
              <span className="font-bold text-2xl">Status</span>: {data?.action}
            </p>
            <p>
              <span className="font-bold text-2xl">By:</span> {data?.action_by_name}
            </p>
          </div>
        </div>
        {data?.list_drivers_can_see_order.length > 0 && (
          <>
            <p className="mt-5 font-bold text-2xl mb-3">:List driver can see order</p>
            <div className="flex flex-col gap-3">
              {data?.list_drivers_can_see_order.map((driver, index) => (
                <div key={index} className="p-3 border rounded-lg flex justify-between">
                  <p>{index + 1}</p>
                  <div>
                    {driver?.map((item, index) => (
                      <p key={index}>{item?.driver_name}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    ));
  };

  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-7xl space-y-5 h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
              {renderDataHistory()}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
